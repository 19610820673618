import React, { useEffect, useState } from 'react'
import { styled, alpha } from '@mui/material/styles'
import { Divider, Menu, MenuItem, MenuProps } from '@mui/material'
import { Button, Typography } from 'flanders-common-ui'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LogoutIcon from '@mui/icons-material/Logout'
import VerifiedUser from '@mui/icons-material/VerifiedUser'
import { useLocation, useNavigate } from 'react-router-dom'
import { login } from 'routes/routes'

const StyledUserMenuDropdown = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export type ActiveUserMenuProps = {
  userId: string
  username: string
}
const ActiveUserMenu: React.FC<ActiveUserMenuProps> = ({
  userId,
  username,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const location = useLocation()
  const navigate = useNavigate()

  const open = Boolean(anchorEl)

  useEffect(() => {
    setAnchorEl(null)
  }, [location])

  const handleClickMenuButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickMyAccount = () => {
    navigate({
      pathname: `/users/${userId}`,
    })
  }

  const handleClickLogout = () => {
    console.warn('TODO: Pending Authentication API')
    navigate({
      pathname: login,
    })
  }

  return (
    <div>
      <Button
        id="active-user-button"
        aria-controls={open ? 'active-user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        onClick={handleClickMenuButton}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <Typography color="gray">{username}</Typography>
      </Button>

      <StyledUserMenuDropdown
        id="active-user-menu"
        MenuListProps={{
          'aria-labelledby': 'active-user-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickMyAccount} disableRipple>
          <VerifiedUser />
          My Account
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClickLogout} disableRipple>
          <LogoutIcon />
          Logout
        </MenuItem>
      </StyledUserMenuDropdown>
    </div>
  )
}

export default ActiveUserMenu
