import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { getValueFromLocalStorage, setValueOnLocalStorage } from 'utils'
import { refreshAccessToken, UserTokens } from 'api/users'

export type GenericErrorResponse = AxiosError<{ message: string[] }>

export type APIFunction<
  RequestParams = Record<string, unknown>,
  ResponseTypeMapped = Record<string, unknown>
> = (requestParams: RequestParams) => Promise<ResponseTypeMapped>

export type APIFunctionNoParams<ResponseTypeMapped = Record<string, unknown>> =
  () => Promise<ResponseTypeMapped>

export type APICurriedFunction<
  RequestParams = Record<string, unknown>,
  ResponseTypeMapped = Record<string, unknown>
> = (requestParams: RequestParams) => () => Promise<ResponseTypeMapped>

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

apiClient.interceptors.request.use(
  (config) => {
    const user = getValueFromLocalStorage<UserTokens>('user')
    if (user?.accessToken) {
      config.headers['Authorization'] = `Bearer ${user.accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

let retry = false

apiClient.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config as AxiosRequestConfig & {
      _retry?: boolean
    }
    if (originalConfig.url !== '/log_in' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !retry) {
        retry = true
        try {
          const user = getValueFromLocalStorage<UserTokens>('user')

          if (!user) return Promise.reject(err)

          const newTokens = await refreshAccessToken(user.refreshToken)
          setValueOnLocalStorage<UserTokens>('user', newTokens)

          return apiClient(originalConfig)
        } catch (_error) {
          return Promise.reject(_error)
        }
      } else if (
        err.response.status === 401 &&
        err.response.statusText === 'Unauthorized'
      ) {
        console.error('Unauthorized User: Force logout')
        // Forces a logout via useAuth hook
        setValueOnLocalStorage<UserTokens>('user', undefined)

        return Promise.reject(err)
      }

      retry = false
    }
    return Promise.reject(err)
  }
)
