import { Site, SiteResponse } from './sites-types'

export const mapSiteResposeToSite = (siteResponse: SiteResponse): Site => ({
  id: siteResponse.id,
  organizationId: siteResponse.organization_id,
  name: siteResponse.name,
  city: siteResponse.city,
  state: siteResponse.state,
  locationCount: siteResponse.location_count,
  status: siteResponse.status,
})
