import theme from 'theme'
import type { Location } from 'api/locations'
import { StatusChipProps, StatusIcon, TestIcon } from 'flanders-common-ui'

const STATUS: Record<
  string,
  Pick<StatusChipProps, 'label' | 'status' | 'icon'>
> = {
  unverified: { label: 'Unverified', status: 'warning' },
  offline: { label: 'Offline', status: 'error' },
  online: { label: 'Online', status: 'ok' },
  deranged: {
    label: 'Sensor Error',
    status: 'error',
    icon: <StatusIcon status="Sensor Error" width={18} height={18} />,
  },
  running: {
    label: 'Test Running',
    status: 'info',
    icon: <TestIcon color={theme.palette.info.main} width={18} height={18} />,
  },
}

export const getLocationNodeStatusChipProps = ({
  node,
  visuallyVerifiedAt,
  lastTest,
}: Location): Pick<StatusChipProps, 'label' | 'status'> => {
  if (!node) {
    return STATUS.offline
  }

  if (lastTest?.status === 'running') {
    return STATUS.running
  }

  switch (node.priority_status) {
    case 'offline':
      return STATUS.offline

    case 'online':
      return visuallyVerifiedAt === null ? STATUS.unverified : STATUS.online

    case 'deranged':
      return STATUS.deranged

    default:
      return STATUS.online
  }
}
