import React from 'react'
import { Paper, Stack } from '@mui/material'
import UserEdit from 'components/organisms/forms/UserEdit'
import { useFetchUserById } from 'api/users'
import { useParams } from 'react-router-dom'

// TODO: Check against actual active user ID
const mockActiveUserId = '0'

const UserDetailsPage: React.FC = () => {
  const { userId = '' } = useParams<{ userId: string }>()
  const { isLoading, data: user } = useFetchUserById(userId)

  return isLoading || !user ? (
    <>Loading</>
  ) : (
    <Stack
      data-test-id="location-add-page-container"
      spacing={1}
      p={1}
      flexGrow={1}
    >
      <Paper
        sx={{
          py: 8,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          alignItems: 'center',
          width: '100%',
          flexGrow: 1,
          justifyContent: 'center',
        }}
      >
        <UserEdit
          email={user.email}
          handleClickDelete={() => {
            console.log('handleClickDelete')
          }}
          headingText={user.id === mockActiveUserId ? 'My Account' : `${user.nameFirst} ${user.nameLast}`}
          showDeleteButton={user.id !== mockActiveUserId}
          showPasswordChangeOption={user.id === mockActiveUserId}
          subtitleText={user.id === mockActiveUserId
            ? 'Manage Your Account Settings'
            : `Edit ${user.nameFirst}'s account information.`
          }
          handleClickSave={() => {
            console.log('handleClickSave')
          }}
          isActiveUser={user.id === mockActiveUserId}
          nameFirst={user.nameFirst}
          nameLast={user.nameLast}
          role={user.role}
        />
      </Paper>
    </Stack>
  )
}

export default UserDetailsPage
