import React from 'react'
import { useParams } from 'react-router-dom'
import theme from 'theme'
import { Grid, Paper, Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import {
  AboveFoldLayout,
  ninetyMinuteImage,
  thirtySecondImage,
  Typography,
} from 'flanders-common-ui'
import { RouteLabels } from 'routes/route-labels'
import { useFetchLocationById } from 'api/locations'
import { useFetchTestHistoryByLocationId } from 'api/tests'
import type { TestHistory } from 'api/tests/test-types'
import DataGrid from 'components/molecules/DataGrid'
import { formatDateOnly, formatTimeOnly } from 'utils/date-utils'
import { testResultDateComparator, testResultStatusComparator, testResultTestTypeComparator } from 'utils/test-result-comparators'
import TestStatusColumn from 'components/organisms/tests/TestStatusColumn'
import LocationDetailsPanel from 'components/molecules/LocationDetailsPanel'


function useColumns(): GridColDef<TestHistory>[] {
  const columns: GridColDef<TestHistory>[] = [
    {
      field: 'startedAt',
      flex: 1,
      headerName: 'Date/Time',
      valueGetter: (params) => params.row,
      sortComparator: testResultDateComparator,
      renderCell: ({ row: testHistoryData }) => {
        const formattedDate = testHistoryData.rescheduledFor
          ? `${formatDateOnly(testHistoryData.rescheduledFor)}*`
          : formatDateOnly(testHistoryData.startedAt)
        const formattedTime = testHistoryData.rescheduledFor
          ? formatTimeOnly(testHistoryData.rescheduledFor)
          : formatTimeOnly(testHistoryData.startedAt)

        return (
          <Stack flexDirection={'column'}>
            <Typography color={theme.palette.grey[900]}>
              {formattedDate}
            </Typography>
            <Typography variant="bodySmall" color={theme.palette.grey[700]}>
              {formattedTime}
            </Typography>
          </Stack>
        )
      },
    },
    {
      field: 'testType',
      flex: 1,
      headerName: 'Type',
      valueGetter: (params) => params.row,
      sortComparator: testResultTestTypeComparator,
      renderCell: ({ row: testHistoryData }) => {
        return (
          <>
            <img
              src={
                testHistoryData.testType === '30sec'
                  ? thirtySecondImage
                  : ninetyMinuteImage
              }
              style={{ marginRight: 4 }}
            />
            <Typography variant="bodySmall">
              {testHistoryData.scheduledType === 'manual_location' ||
              testHistoryData.scheduledType === 'manual_sitewide'
                ? 'Manual'
                : 'Automatic'}
            </Typography>
          </>
        )
      },
    },
    {
      field: 'status',
      flex: 1,
      headerName: 'Result',
      valueGetter: (params) => params.row,
      sortComparator: testResultStatusComparator,
      renderCell: ({ row: testHistoryData }) => {
        if (testHistoryData.status === null) {
          return <div>--</div>
        } else {
          return (
            <TestStatusColumn
              testHistoryData={testHistoryData}
              testId={`location-details-test-history-status-chip-${testHistoryData.id.toString()}`}
            />
          )
        }
      },
    },
  ]

  return columns
}

const LocationDetailsPage: React.FC = () => {
  const { siteId = '' } = useParams<{ siteId: string }>()
  const { isLoading, data: locationDetailsData } = useFetchLocationById(siteId)
  const columns = useColumns()

  return isLoading || !locationDetailsData ? (
    <>Loading</>
  ) : (
    <Grid container spacing={1}>
      <AboveFoldLayout
        breadcrumbRoutes={[RouteLabels[1]]}
        heading={locationDetailsData.name}
      />

      <Grid container item spacing={2}>
        <Grid item xs={12} lg={8}>
          <Paper
            sx={{
              px: 2,
              paddingTop: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack
              spacing={2}
              sx={{
                flex: 1,
              }}
            >
              <DataGrid<TestHistory, { locationId: string }>
                columns={columns}
                dataId="location-test-history-details"
                requestExtraParams={{
                  locationId: '123',
                }}
                useFetchPaginatedData={useFetchTestHistoryByLocationId}
              />
            </Stack>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Stack spacing={2} maxWidth={375}>
            <div>Upcoming Tests</div>
            <LocationDetailsPanel
              locationFixture={locationDetailsData.fixture}
              locationNode={locationDetailsData.node}
              notes={locationDetailsData.notes}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LocationDetailsPage
