export type Role = EMCAdmin | Admin | Viewer
export type EMCAdmin = 'emc-admin'
export type Admin = 'admin'
export type Viewer = 'viewer'

export function isRoleType(testString: unknown): testString is Role {
  return (
    typeof testString === 'string' &&
    testString !== null &&
    (testString === 'emc-admin' ||
      testString === 'admin' ||
      testString === 'viewer')
  )
}

export type User = {
  id: string
  nameFirst: string
  nameLast: string
  email: string
  organizationId: string
  role: Role
}

export type UserResponse = {
  id: string
  name_first: string
  name_last: string
  email: string
  organization_id: string
  role: Role
}

export type UserTokensResponse = {
  access_token: string
  refresh_token: string
  user: UserResponse
}

export type UserTokens = {
  accessToken: string
  refreshToken: string
  user: User
}

export type UserCredentials = {
  email: string
  password: string
}
