import React from 'react'
import { MarkEmailReadOutlined } from '@mui/icons-material'
import SuccessLayout from 'components/templates/success/SuccessLayout'

export type UserInviteSentProps = {
  handleClickFinish: () => void
  handleClickSecondary: () => void
}
const UserInviteSent: React.FC<UserInviteSentProps> = ({
  handleClickFinish,
  handleClickSecondary,
}) => {
  return (
    <SuccessLayout 
      handleClickPrimary={handleClickFinish}
      heading='User Added Successfully'
      handleClickSecondary={handleClickSecondary}
      icon={<MarkEmailReadOutlined
        style={{
          height: '96px',
          width: '96px',
        }}
        />}
      primaryLabel='Finish'
      secondaryLabel='Add Another User'
      subtitle='We&apos;ve sent an invite email with instructions for setting up their account'
    />
  )
}

export default UserInviteSent
