import React from 'react'
import { Box, Stack } from '@mui/material'
import { AppBar, Footer } from 'flanders-common-ui'
import { Outlet } from 'react-router-dom'
import theme from 'theme'
import PrivacyPolicy from '../legal/PrivacyPolicy'
import TermsAndConditions from '../legal/TermsAndConditions'

const VisitorLayout: React.FC = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        color="inherit"
        position="absolute"
        sx={{ boxShadow: theme.customShadows.subtle }}
      >
        {/* TODO: Logo */}
      </AppBar>
      <Box
        component="main"
        sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            mt: 10,
            height: '85%',
          }}
        >
          <Outlet />
        </Stack>
        <Footer
          privacyPolicy={<PrivacyPolicy />}
          termsAndConditions={<TermsAndConditions />}
        />
      </Box>
    </Box>
  )
}

export default VisitorLayout
