import { apiClient } from 'api/http-common'
import type { APICurriedFunction } from 'api/http-common'
import { Page, PageRequestParams } from 'api/page'
import type { LocationTestType, ScheduledType, SensorResults, TestHistory, TestHistoryResponse, TestStatus } from './test-types'
import { mapTestHistoryResponseToTestHistory } from 'api/tests'

type TestHistoryPageRequestParams = PageRequestParams & {
  locationId: string
}

export const fetchTestHistoryByLocationId: APICurriedFunction<
  TestHistoryPageRequestParams,
  Page<TestHistory>
> = (params: TestHistoryPageRequestParams) => async () => {
  const { data } = await apiClient.get<Page<TestHistoryResponse>>(
    'tests/history',
    {
      params: {
        location_id: params.locationId,
        page: params.page,
        page_size: params.page_size,
      },
    }
  )

  return {
    ...data,
    entries: data.entries.map(mapTestHistoryResponseToTestHistory),
  }
}

///////////////
// TODO: Delete when API is implemented and/or UI is complete
// TODO: Be sure to update parent use function too!
export const mockFetchTestHistoryByLocationId: APICurriedFunction<
  TestHistoryPageRequestParams,
  Page<TestHistory>
> = () => {
  const sensorResults: SensorResults = {
    sensor: 1,
    message: 'bad_battery_detected',
  }
  const status: TestStatus = 'failed'
  const testType: LocationTestType = '30sec'
  const scheduledType: ScheduledType = 'manual_location'

  return async () => {
    const mockData = await Promise.resolve({
      entries: [
        {
          id: 'id',
          rescheduled_for: "2022-09-18T12:00:30",
          sensor_results: [sensorResults],
          started_at: "2022-09-18T12:00:30",
          status: status,
          test_type: testType,
          scheduled_type: scheduledType,
        }
      ],
      page: 1,
      page_size: 5,
      total_entries: 1,
      total_pages: 1,
    })
  
    return {
      page: 1,
      page_size: 5,
      total_entries: 1,
      total_pages: 1,
      entries: mockData.entries.map(mapTestHistoryResponseToTestHistory),
    }
  }
}


