import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  PasswordField,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from 'flanders-common-ui'
import { MenuItem, Stack } from '@mui/material'
import { isRoleType, Role } from 'api/users'
import PasswordEdit from './PasswordEdit'

export type UserEditProps = {
  email: string
  handleClickDelete?: () => void
  handleClickSave: () => void
  headingText: string
  isActiveUser?: boolean
  nameFirst: string
  nameLast: string
  role: Role
  showDeleteButton?: boolean
  showPasswordFields?: boolean
  showPasswordChangeOption?: boolean
  subtitleText: string
}

const validatePassword = (password: string, passwordConfirm: string) => {
  const passwordLength = password.length > 12
  const passwordConfirmed = password === passwordConfirm

  return passwordLength && passwordConfirmed
}

const UserEdit: React.FC<UserEditProps> = ({
  email,
  handleClickDelete = () => {},
  handleClickSave,
  headingText,
  isActiveUser = false,
  nameFirst,
  nameLast,
  role,
  showDeleteButton = false,
  showPasswordFields = false,
  showPasswordChangeOption = false,
  subtitleText,
}) => {
  const [nameFirstDraft, setNameFirstDraft] = useState(nameFirst)
  const [nameLastDraft, setNameLastDraft] = useState(nameLast)
  const [emailDraft, setEmailDraft] = useState(email)
  const [roleDraft, setRoleDraft] = useState(role)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [showPasswordChange, setShowPasswordChange] = useState(false)

  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [isSaveDisabled, setIsSaveDisabled] = useState(showPasswordFields ? true : false)

  useEffect(() => {
    if (showPasswordFields) {
      const isValidPassword = validatePassword(password, passwordConfirm)
      setIsSaveDisabled(!isValidPassword)
    }
  }, [password, passwordConfirm])

  const handleChangeNameFirst = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNameFirstDraft(event.target.value)
  }
  const handleChangeNameLast = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameLastDraft(event.target.value)
  }
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailDraft(event.target.value)
  }
  const handleChangeRole = (event: SelectChangeEvent<Role>) => {
    if (isRoleType(event.target.value)) {
      setRoleDraft(event.target.value)
    }
  }

  return (
    <Stack alignItems="center" spacing={2} maxWidth={375} width="100%">
      <Typography variant="h2" fontWeight="bold">
        {headingText}
      </Typography>

      <Typography variant="subtitle1" color="gray">
        {subtitleText}
      </Typography>

      <TextField
        autoFocus
        fullWidth
        id="name-first"
        label="First Name"
        margin="dense"
        value={nameFirstDraft}
        onChange={handleChangeNameFirst}
        variant="standard"
      />

      <TextField
        fullWidth
        id="name-last"
        label="Last Name"
        margin="dense"
        value={nameLastDraft}
        onChange={handleChangeNameLast}
        variant="standard"
      />

      <TextField
        fullWidth
        disabled={isActiveUser}
        id="email"
        label="Email"
        margin="dense"
        value={emailDraft}
        onChange={handleChangeEmail}
        variant="standard"
      />

      {isActiveUser && (
        <TextField
          disabled={true}
          fullWidth
          id="role"
          label="Role"
          margin="dense"
          value={roleDraft}
          variant="standard"
        />
      )}

      {!isActiveUser && (
        <Select
          fieldLabel="Role"
          onChange={handleChangeRole}
          id="select-role"
          name="Role"
          value={roleDraft}
        >
          <MenuItem key="emc-admin" value="emc-admin">
            EMC Admin
          </MenuItem>
          <MenuItem key="admin" value="admin">
            Admin
          </MenuItem>
          <MenuItem key="viewer" value="viewer">
            Viewer
          </MenuItem>
        </Select>
      )}

      {showPasswordFields && (
      <>
        <PasswordField
          fullWidth
          name="password"
          label="Password"
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setPassword(event.currentTarget.value)
          }}
        />
        <PasswordField
          fullWidth
          name="password"
          label="Repeat Password"
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setPasswordConfirm(event.currentTarget.value)
          }}
        />
      </>
      )}

      {showPasswordChangeOption && (
        <Button
          onClick={() => setShowPasswordChange(true)}
          variant="text"
        >
          Update Password
        </Button>
      )}

      <Stack pt={2} spacing={2} width="100%">
        <Button disabled={isSaveDisabled} onClick={handleClickSave}>Save Changes</Button>
        {showDeleteButton && (
          <Button
            onClick={() => setShowDeleteConfirm(true)}
            variant="text"
            color="error"
          >
            Delete User
          </Button>
        )}
      </Stack>

      <Dialog
        isOpen={showDeleteConfirm}
        title="Delete User?"
        subtitle={
          <>
            Are you sure you want to delete this user?
            <br />
            This action cannot be undone.
          </>
        }
        handleClickCancel={() => setShowDeleteConfirm(false)}
      >
        <Button color="error" fullWidth onClick={handleClickDelete}>
          Confirm Delete
        </Button>
      </Dialog>
      
      { showPasswordChangeOption && (
      <Dialog
        isOpen={showPasswordChange}
        subtitle={`Change ${nameFirst}'s password`}
        title="Update Password"
        handleClickCancel={() => setShowPasswordChange(false)}
      >
        <PasswordEdit handleClickSave={() => setShowPasswordChange(false) } />
      </Dialog>
      )}

    </Stack>
  )
}

export default UserEdit
