/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: Remove line above when we add real API call
import { APICurriedFunction } from 'api/http-common'
import { mapSiteResposeToSite } from './mapSiteResposeToSite'
import { Site, SiteResponse } from './sites-types'

export type SiteByIdRequestParams = {
  siteId: string
}

export const fetchSiteById: APICurriedFunction<string, Site> =
  (siteId: string) => async () => {
    const siteResponse: SiteResponse = {
      id: '1',
      name: 'Store 1455',
      city: 'Plainstown',
      state: 'PN',
      location_count: 10,
      status: 'status',
      organization_id: '1',
    }

    return await Promise.resolve(mapSiteResposeToSite(siteResponse))
  }
