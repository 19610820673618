import React from 'react'
import { MarkEmailUnreadOutlined } from '@mui/icons-material'
import SuccessLayout from 'components/templates/success/SuccessLayout'

export type ResetLinkSentScreenProps = {
  handleClickReturn: () => void
}
const ResetLinkSentScreen: React.FC<ResetLinkSentScreenProps> = ({
  handleClickReturn,
}) => {
  return (
    <SuccessLayout 
      handleClickPrimary={handleClickReturn}
      heading='Reset Link Sent'
      icon={<MarkEmailUnreadOutlined
        style={{
          height: '96px',
          width: '96px',
        }}
      />}
      primaryLabel='Return to Login'
      subtitle='If you have an email address in the system, we&apos;ve sent you a link
      to reset your password'
    />
  )
}

export default ResetLinkSentScreen
