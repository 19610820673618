import { GenericErrorResponse } from 'api/http-common'
// import { fetchLocationById, Location } from 'api/locations'
import { Location, mockFetchLocationById } from 'api/locations'
import { useQuery } from 'react-query'

export const useFetchLocationById = (locationId: string) => {
  return useQuery<Location, GenericErrorResponse>(
    ['fetchLocationById', locationId],
    // fetchLocationById(locationId)
    mockFetchLocationById(locationId)
  )
}
