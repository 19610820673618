//Top Level Routes
export const dashboard = '/dashboard'
export const locations = '/locations'
export const login = '/login'
export const forgotPassword = '/forgot-password'
export const users = '/users'
export const userInviteSend = `${users}/invite-send`
export const userInviteAccept = `${users}/invite-accept`
export const userDetails = `${users}/:userId`
export const sites = '/sites'
export const siteDetails = `${sites}/:siteId`
export const siteLocations = `${siteDetails}/locations`
export const siteLocationDetails = `${siteLocations}/:locationId`
