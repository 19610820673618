import React from 'react'
import { Stack } from '@mui/material'
import { Button, Typography } from 'flanders-common-ui'

export type SuccessLayoutProps = {
  handleClickPrimary: () => void
  handleClickSecondary?: () => void | undefined
  heading: string
  icon: React.ReactNode
  primaryLabel: string
  secondaryLabel?: string
  subtitle: string
}
const SuccessLayout: React.FC<SuccessLayoutProps> = ({
  handleClickPrimary,
  handleClickSecondary = () => {},
  heading,
  icon,
  primaryLabel,
  secondaryLabel = '',
  subtitle,
}) => {
  return (
    <Stack
      alignItems="center"
      spacing={2}
      maxWidth={375}
      width="100%"
    >
      <Stack alignItems="center">
        <Typography variant="h1" fontWeight="bold">
          {heading}
        </Typography>
        <Typography variant="subtitle1" color="gray">
          {subtitle}
        </Typography>
      </Stack>

      <Stack width="100%" alignItems="center">
        {icon}
        <Button fullWidth onClick={handleClickPrimary}>{primaryLabel}</Button>
        { secondaryLabel.length && (
          <Button onClick={handleClickSecondary} variant='text'>{secondaryLabel}</Button>)
        }
      </Stack>
    </Stack>
  )
}

export default SuccessLayout
