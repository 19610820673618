import { useQuery } from 'react-query'
import { PageRequestParams } from 'api/page'
// import { fetchTestHistoryByLocationId } from 'api/tests'
import { mockFetchTestHistoryByLocationId } from 'api/tests'

//TODO: Resolve duplicated type
type TestHistoryPageRequestParams = PageRequestParams & {
  locationId: string
}

export const useFetchTestHistoryByLocationId = (
  params: TestHistoryPageRequestParams
) => {
  return useQuery(
    ['fetchTestHistoryByLocationId', params],
    // fetchTestHistoryByLocationId(params)
    mockFetchTestHistoryByLocationId(params)
  )
}
