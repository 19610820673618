import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { NavLink, useNavigate } from 'react-router-dom'
import DataGrid from 'components/molecules/DataGrid'
import { Paper, Stack } from '@mui/material'
import { useFetchUsersListPaginated, User } from 'api/users'
import { AboveFoldLayout, Button } from 'flanders-common-ui'
import { userInviteSend } from 'routes/routes'
import { RouteLabels } from 'routes/route-labels'

function useColumns(): GridColDef<User>[] {
  const columns: GridColDef<User>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      renderCell: ({ row: user }) => {
        return (
          <NavLink key={user.id} to={`/users/${user.id}`}>
            {user.nameFirst} {user.nameLast}
          </NavLink>
        )
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
    },
  ]

  return columns
}

const UsersPage: React.FC = () => {
  const columns = useColumns()
  const navigate = useNavigate()
  const routelabelData = RouteLabels.find(obj => {
    return obj.key === 'Users'
  })

  return (
    <>
    {routelabelData && (
      <AboveFoldLayout
        breadcrumbRoutes={[routelabelData]}
        heading="Users"
      >
        <Button onClick={() => { navigate(userInviteSend) }}>+ New User</Button>
      </AboveFoldLayout>
    )}
    <Stack component={Paper} spacing={1} px={2} pt="20px">
      <Stack width="100%">
        <DataGrid<User>
          columns={columns}
          dataId="users-table"
          requestExtraParams={{}}
          useFetchPaginatedData={useFetchUsersListPaginated}
        />
      </Stack>
    </Stack>
    </>
  )
}

export default UsersPage
