import React from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Container, Divider, IconButton, Toolbar } from '@mui/material'
import {
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
} from '@mui/icons-material'
import { AppBar, Drawer, Footer } from 'flanders-common-ui'
// import Logo from 'logo.svg'
import PrivacyPolicy from '../legal/PrivacyPolicy'
import TermsAndConditions from '../legal/TermsAndConditions'
import Nav from './Nav'
import { RouteLabels } from 'routes/route-labels'
import ActiveUserMenu from './ActiveUserMenu'

type SidebarNavigationLayoutProps = {
  id?: string
}

const drawerWidth = 240
const navRoutesFiltered = RouteLabels.filter(
  (routeLabelData) => routeLabelData.Icon
)

// TODO: replace with API call
const activeUsername = 'Sterling Archer'
const activeUserId = '008'

const SidebarNavigationLayout: React.FC<SidebarNavigationLayoutProps> = ({
  ...sidebarNavigationLayout
}) => {
  const [open, setOpen] = React.useState(true)

  const toggleDrawer = () => {
    setOpen((open) => !open)
  }

  return (
    <Box sx={{ display: 'flex' }} {...sidebarNavigationLayout}>
      <AppBar
        color="inherit"
        position="absolute"
        open={open}
        drawerWidth={drawerWidth}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {open && <span></span>}{' '}
        {/* Span provides space-between counter-weight since IconButton is display none when open is true */}
        <ActiveUserMenu userId={activeUserId} username={activeUsername} />
      </AppBar>

      {/* Body */}
      <Drawer data-test-id="navigation-drawer" open={open} variant="permanent">
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: [1],
          }}
        >
          {/* <img src={Logo} alt="EMC Logo" /> */}
          <IconButton aria-label="toggle drawer" onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Nav navRouteData={navRoutesFiltered} />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Divider />
        <Container
          maxWidth="xl"
          data-test-id="main-content-section"
          sx={{
            mt: 4,
            minHeight: '85%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
          }}
        >
          <Outlet />
        </Container>
        <Footer
          privacyPolicy={<PrivacyPolicy />}
          termsAndConditions={<TermsAndConditions />}
        />
      </Box>
    </Box>
  )
}

export default SidebarNavigationLayout
