import { APICurriedFunction, apiClient, GenericErrorResponse } from 'api/http-common'
import { Page, PageRequestParams } from 'api/page'
import type { Location, LocationResponse } from 'api/locations'
import { mapLocationResponseToLocation } from 'api/locations'

export type LocationsListRequestParams = PageRequestParams & {
  requiringAttention?: boolean
}

export const fetchLocationsListPaginated: APICurriedFunction<
  LocationsListRequestParams,
  Page<Location>
> = (rawParams: LocationsListRequestParams) =>
  async () => {
    try {
      const params = {
        page: rawParams.page,
        page_size: rawParams.page_size,
        ...(rawParams.requiringAttention === true && {
          requiring_attention: true,
        }),
      }
      const response = await apiClient.get<Page<LocationResponse>>('/locations', {
        params,
      })

      return {
        ...response.data,
        entries: response.data.entries.map(mapLocationResponseToLocation),
      }
    }  catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? ['Fetch Locations List Error']
  }
}

///////////////
// TODO: Delete when API is implemented and/or UI is complete
// TODO: Be sure to update useFetchLocationsListPaginated too!
import type { FixtureType } from 'api/locations'
import { NodeStatus } from 'api/nodes'
import { LocationTestType, ScheduledTestType, ScheduledType, TestResults, TestStatus } from 'api/tests'

export const fetchMockLocationsListPaginated: APICurriedFunction<
  LocationsListRequestParams,
  Page<Location>
> = () => {
  return async () => {
    const fixture: FixtureType = 'combination_1'
    const testResult: TestResults = {
      id: 'testResultId',
      endedAt: 'endedAt',
      scheduledFor: 'scheduledFor',
      scheduledType: 'automatic_annual' as ScheduledTestType,
      sensorResults: [],
      startedAt: 'startedAt',
      status: 'passed' as TestStatus,
      testType: '30sec' as LocationTestType
    }
    const mockData = await Promise.resolve({
      entries: [
        {
          id: 'id',
          fixture: {
            fixture_model_number: 'fixture_model_number',
            battery_model_number: 'battery_model_number',
            fixture_type: fixture,
            voltage: 1,
          },
          last_test: {
            id: 'last_test_id',
            rescheduled_for: 'rescheduled_for',
            sensor_results: [],
            started_at: 'started_at',
            status: 'passed' as TestStatus,
            test_type: '30sec' as LocationTestType,
            scheduled_type: 'manual_location' as ScheduledType
          },
          name: 'LocationName',
          node:  {
            id: 'nodeid',
            location_id: 'location_id',
            serial_number: 'serial_number',
            status: 'offline' as NodeStatus,
            statuses: ['offline' as NodeStatus],
            priority_status: 'offline' as NodeStatus,
          },
          notes: 'notes',
          organization_id: 'organization_id',
          test_results: [testResult],
          visually_verified_at: null
        }
      ],
      page: 1,
      page_size: 5,
      total_entries: 1,
      total_pages: 1,
    })
  
    return {
      page: 1,
      page_size: 5,
      total_entries: 1,
      total_pages: 1,
      entries: mockData.entries.map(mapLocationResponseToLocation),
    }
  }
}
