import React, { useState } from 'react'
import { Stack } from '@mui/material'
import ForgotPasswordScreen from './ForgotPasswordScreen'
import ResetLinkSentScreen from './ResetLinkSentScreen'
import { useNavigate } from 'react-router-dom'
import { login } from 'routes/routes'

const ForgotPasswordPage: React.FC = () => {
  const [activeScreen, setActiveScreen] = useState('forgot')
  const navigate = useNavigate()

  const handleClickSubmit = (email: string) => {
    console.log(email) //TODO: Post to server
    setActiveScreen('sent')
  }

  const handleClickReturn = () => {
    navigate(login)
  }

  return (
    <Stack
      alignItems="center"
      boxShadow={2}
      spacing={2}
      padding={2}
      maxWidth={375}
      width="100%"
    >
      {activeScreen === 'forgot' ? (
        <ForgotPasswordScreen handleClickSubmit={handleClickSubmit} />
      ) : (
        <ResetLinkSentScreen handleClickReturn={handleClickReturn} />
      )}
    </Stack>
  )
}

export default ForgotPasswordPage
