type NodeStatus = 'offline' | 'online' | 'deranged'

function isNodeStatus(status: unknown): status is NodeStatus {
  return (
    status !== null &&
    typeof status === 'string' &&
    ['offline', 'online', 'deranged'].includes(status)
  )
}

type Node = {
  id: string
  location_id: string
  serial_number: string
  status: NodeStatus
  statuses: NodeStatus[]
  priority_status: NodeStatus
}

export type { Node, NodeStatus }
export { isNodeStatus }
