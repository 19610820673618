import React, { useEffect, useState } from 'react'
import {
  Button,
  PasswordField,
} from 'flanders-common-ui'
import { Stack } from '@mui/material'

export type PasswordEditProps = {
  handleClickSave: () => void
}

const validatePassword = (password: string, passwordConfirm: string) => {
  const passwordLength = password.length > 12
  const passwordConfirmed = password === passwordConfirm

  return passwordLength && passwordConfirmed
}

const PasswordEdit: React.FC<PasswordEditProps> = ({
  handleClickSave,
}) => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  useEffect(() => {
    const isValidPassword = validatePassword(newPassword, newPasswordConfirm)
    setIsSaveDisabled(!isValidPassword)
  }, [currentPassword, newPassword, newPasswordConfirm])

  const handleClickSubmit = () => {
    console.log('Post to server')
    handleClickSave()
  }

  return (
    <Stack
      alignItems="center"
      maxWidth={375}
      spacing={2}
      width="100%"
    >
      <PasswordField
        fullWidth
        name="current-password"
        label="Current Password"
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setCurrentPassword(event.currentTarget.value)
        }}
      />
      <PasswordField
        fullWidth
        name="new-password"
        label="New Password"
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setNewPassword(event.currentTarget.value)
        }}
      />
      <PasswordField
        fullWidth
        name="repeat-new-password"
        label="Repeat New Password"
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setNewPasswordConfirm(event.currentTarget.value)
        }}
      />

      <Button disabled={isSaveDisabled} fullWidth onClick={handleClickSubmit}>
        Submit
      </Button>
    </Stack>
  )
}

export default PasswordEdit
