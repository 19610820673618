import { createTheme } from '@mui/material/styles'
import '@mui/x-data-grid/themeAugmentation'

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: {
      subtle: string
    }
    fontWeight: {
      light: number
      regular: number
      semiBold: number
      bold: number
    }
    units: {
      xs: number
      small: number
      medium: number
      large: number
      xl: number
      xxl: number
      xxxl: number
    }
  }
  interface TypographyVariants {
    jumbo: React.CSSProperties
    display: React.CSSProperties
    body: React.CSSProperties
    bodySmall: React.CSSProperties
    bodyXs: React.CSSProperties
    bodyLarge: React.CSSProperties
    formHeader: React.CSSProperties
    label: React.CSSProperties
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    customShadows?: {
      subtle?: string
    }
    fontWeight?: {
      light?: number
      regular?: number
      semiBold?: number
      bold?: number
    }
    units?: {
      xs: number
      small: number
      medium: number
      large: number
      xl: number
      xxl: number
      xxxl: number
    }
  }
  interface TypographyVariantsOptions {
    jumbo?: React.CSSProperties
    display?: React.CSSProperties
    body?: React.CSSProperties
    bodySmall?: React.CSSProperties
    bodyXs?: React.CSSProperties
    bodyLarge?: React.CSSProperties
    formHeader?: React.CSSProperties
    label?: React.CSSProperties
  }
  interface Palette {
    accent: Palette['primary']
    UI: Palette['primary']
  }
  interface PaletteOptions {
    accent: PaletteOptions['primary']
    UI: PaletteOptions['primary']
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    jumbo: true
    display: true
    body: true
    bodySmall: true
    bodyXs: true
    bodyLarge: true
    formHeader: true
    label: true
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    poster: true
  }
}

// A custom theme for this app
const theme = createTheme({
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E7ECF3', // UI Main Color
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: `1px solid #E7ECF3`, // UI/main color
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&&': {
            marginBottom: '16px',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
          borderColor: 'primary.light',
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:active':
            {
              outline: 'none',
            },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: '#838889',
            textTransform: 'uppercase',
            fontSize: 14,
            fontWeight: 600,
          },
          '& .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
          },
          '& .MuiDataGrid-columnHeader:focus, .ccAYGc .MuiDataGrid-cell:focus':
            {
              outline: `solid '#FBFBFC' 1px`,
            },
          '& .MuiDataGrid-cell': {
            borderColor: '#E7ECF3',
          },
          '& .MuiDataGrid-row:nth-child(even):not(:hover)': {
            backgroundColor: '#FBFBFC',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'rgba(0,0,0,0.04)',
          },
          '& .MuiDataGrid-cell a': {
            fontWeight: 600,
            textDecoration: 'none',
            fontSize: 16,
            color: '#003DA5',
          },
          '& .MuiDataGrid-cell a:visited': {
            color: '#003DA5',
          },
          '& .MuiDataGrid-footerContainer': {
            justifyContent: 'center',
            fontSize: '12px',
            borderColor: '#E7ECF3',
          },
          '& [class^="MuiTablePagination"]': {
            fontSize: 14,
          },
          '& .MuiDataGrid-columnHeaders': {
            borderColor: '#E7ECF3',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#003DA5',
      light: '#809ED2',
      dark: '#012054',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#ADB6C4',
      light: '#D6DBE1',
      dark: '#838D9B',
      contrastText: '#FFFFFF',
    },
    accent: {
      main: '#FF7F32',
      light: '#FFBF99',
      dark: '#CE5E1B',
      contrastText: '#FFFFFF',
    },
    UI: {
      main: '#E7ECF3',
      light: '#FBFBFC',
      dark: '#A1AAB7',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#F5004A',
      light: '#FFAFB4',
      dark: '#D3003F',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FFB800',
      light: '#FFF7EA',
      dark: '#EEAB00',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#007CEE',
      light: '#D6DBE1',
      dark: '#ADB6C4',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#00B0A6',
      light: '#E6F7F6',
      dark: '#80D8D2',
      contrastText: '#FFFFFF',
    },
    grey: {
      50: '#E9E9E9', // gray 10 in styleguide
      100: '#E9E9E9', // gray 10 in styleguide
      200: '#C0C0C7', // gray 20 in styleguide
      300: '#C0C0C7', // gray 20 in styleguide
      400: '#9EA2A2', // gray 40 in styleguide
      500: '#9EA2A2', // gray 40 in styleguide
      600: '#838889', // gray 60 in styleguide
      700: '#838889', // gray 60 in styleguide
      800: '#53565A', // gray 80 in styleguide
      900: '#212721', // gray 100 in styleguide
      A100: '#f0f0f0',
      A200: '#C0C0C7',
      A400: '#9EA2A2',
      A700: '#838889',
    },
    background: {
      default: '#FBFBFC',
    },
  },
  fontWeight: {
    light: 300,
    regular: 400,
    semiBold: 600,
    bold: 700,
  },
  typography: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    body1: {
      fontSize: 16,
    },
    body: {
      fontSize: 16,
      lineHeight: 1,
    },
    bodyXs: {
      fontSize: 12,
    },
    bodySmall: {
      fontSize: 14,
    },
    bodyLarge: {
      fontSize: 18,
    },
    jumbo: {
      fontFamily: 'Nunito',
      fontSize: 55,
    },
    display: {
      fontFamily: 'Nunito',
      fontSize: 32,
    },
    h1: {
      fontFamily: 'Nunito',
      fontSize: 28,
    },
    h2: {
      fontFamily: 'Nunito',
      fontSize: 24,
    },
    h3: {
      fontFamily: 'Nunito',
      fontSize: 20,
    },
    h4: {
      fontFamily: 'Nunito',
      fontSize: 18,
    },
    h5: {
      fontFamily: 'Nunito',
    },
    h6: {
      fontFamily: 'Nunito',
    },
  },
  customShadows: {
    subtle:
      '0px 0.16603538393974304px 0.30439820885658264px 0px #00000001, 0px 0.3990061283111572px 0.7315112352371216px 0px #00000002, 0px 0.7512931227684021px 1.3773707151412964px 0px #00000003, 0px 1.3401784896850586px 2.45699405670166px 0px #00000003, 0px 2.5066566467285156px 4.595537185668945px 0px #00000004, 0px 6px 11px 0px #00000005',
  },
  units: {
    xs: 2,
    small: 4,
    medium: 8,
    large: 16,
    xl: 24,
    xxl: 32,
    xxxl: 64,
  },
})

export default theme
