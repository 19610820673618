import React from 'react'
import { StatusChart, TableBanner, Typography } from 'flanders-common-ui'
import { GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import { NavLink } from 'react-router-dom'
import DataGrid from 'components/molecules/DataGrid'
import { Paper, Stack } from '@mui/material'
import theme from 'theme'
import { Site, useFetchSitesListPaginated } from 'api/sites'

function useColumns(): GridColDef<Site>[] {
  const columns: GridColDef<Site>[] = [
    {
      field: 'name',
      headerName: 'Site Name',
      flex: 3,
      renderCell: ({ row: site }) => {
        return (
          <NavLink key={site.id} to={`/sites/${site.id}`} state={site}>
            {site.name}
          </NavLink>
        )
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      valueGetter: ({ row: site }) => `${site.city}, ${site.state}`,
    },
    {
      field: 'locationCount',
      headerName: 'Locations',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      sortComparator: gridStringOrNumberComparator,
      renderCell: () => {
        return (
          <Stack
            width={'100%'}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack>
              <Typography
                color={theme.palette.error.main}
                weight={'semiBold'}
                marginBottom={1}
                variant="body"
              >
                9 Locations
              </Typography>
              <Typography
                color={theme.palette.grey[400]}
                fontStyle="italic"
                variant="body"
              >
                Require Attention
              </Typography>
            </Stack>
            <CancelIcon
              color="error"
              data-testid="cancel-icon"
              width={24}
              height={24}
            />
          </Stack>
        )
      },
    },
  ]

  return columns
}

const DashboardPage: React.FC = () => {
  const columns = useColumns()
  const healthyCount = 1
  const requireAttentionCount = 2
  const runningCount = 3
  const totalCount = 6

  return (
    <Stack component={Paper} spacing={1} px={2} pt="20px">
      <StatusChart
        healthy={healthyCount}
        requireAttention={requireAttentionCount}
        running={runningCount}
        targetName={'SITES'}
        totalCount={totalCount}
      />

      <Stack width="100%">
        <TableBanner
          requireAttentionCount={requireAttentionCount}
          targetNamePlural={'Sites'}
          targetNameSingular={'Site'}
        />
        <DataGrid<Site>
          columns={columns}
          dataId="sites-requiring-attention-table"
          requestExtraParams={{
            requiringAttention: true,
          }}
          useFetchPaginatedData={useFetchSitesListPaginated}
        />
      </Stack>
    </Stack>
  )
}

export default DashboardPage
