import React from 'react'
import theme from 'theme'
import { Grid, Stack } from '@mui/material'
import type { LocationFixture } from 'api/locations'
import type { Node } from 'api/nodes'
import { DetailsPanel, FixtureTypeImage, Typography } from 'flanders-common-ui'

type LocationDetailsPanelType = {
  locationFixture: LocationFixture | null
  locationNode: Node | null
  notes: string | null
}

const LocationDetailsPanel: React.FC<LocationDetailsPanelType> = ({
  locationFixture,
  locationNode,
  notes,
}) => {
  return (
    <DetailsPanel heading="Location Details">
      {locationFixture?.fixtureType && (
        <Stack
          alignItems={'center'}
          borderBottom={'solid 1px'}
          borderColor={theme.palette.UI.main}
          padding={2}
          position="relative"
        >
          <FixtureTypeImage fixtureType={locationFixture.fixtureType} />
        </Stack>
      )}

      <Grid container flexDirection={'column'}>
        <Stack
          flex={1}
          borderBottom={'solid 1px'}
          borderColor={theme.palette.UI.main}
          marginBottom={2}
          paddingBottom={2}
        >
          <Grid item paddingBottom={1}>
            <Typography weight="bold" variant="h4">
              Fixture Battery
            </Typography>
          </Grid>

          <Grid
            alignItems="center"
            display="flex"
            flex={1}
            flexDirection="row"
            gap={1}
            justifyContent="center"
          >
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Typography variant="bodySmall" color={theme.palette.grey[600]}>
                Part Number
              </Typography>
              <Typography variant="body" fontWeight="semiBold">
                {locationFixture?.batteryModelNumber}
              </Typography>
            </Grid>

            <Stack
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Typography variant="bodySmall" color={theme.palette.grey[600]}>
                Voltage
              </Typography>
              <Typography variant="body" fontWeight="semiBold">
                {locationFixture?.voltage}
              </Typography>
            </Stack>
          </Grid>
        </Stack>

        <Stack
          flex={1}
          borderBottom={'solid 1px'}
          borderColor={theme.palette.UI.main}
          marginBottom={2}
          paddingBottom={2}
        >
          <Grid item paddingBottom={1}>
            <Typography weight="bold" variant="h4">
              Node Information
            </Typography>
          </Grid>

          <Grid
            alignItems="center"
            display="flex"
            flex={1}
            flexDirection="row"
            gap={1}
            justifyContent="center"
          >
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Typography variant="bodySmall" color={theme.palette.grey[600]}>
                Serial Number
              </Typography>
              <Typography variant="body" fontWeight="semiBold">
                {locationNode?.serial_number}
              </Typography>
            </Grid>
          </Grid>
        </Stack>

        <Stack flex={1}>
          <Grid item paddingBottom={1}>
            <Typography weight="bold" variant="h4">
              Notes
            </Typography>
          </Grid>

          <Grid
            alignItems="center"
            display="flex"
            flex={1}
            flexDirection="row"
            gap={1}
            justifyContent="center"
          >
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Typography variant="body" fontWeight="semiBold">
                {notes !== null && notes.length ? notes : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </DetailsPanel>
  )
}

export default LocationDetailsPanel
