import { APICurriedFunction } from 'api/http-common'
import { Page, PageRequestParams } from 'api/page'
import { mapSiteResposeToSite } from './mapSiteResposeToSite'
import { Site, SiteResponse } from './sites-types'

export type SitesListRequestParams = PageRequestParams & {
  requiringAttention?: boolean
}

export const fetchSitesListPaginated: APICurriedFunction<
  SitesListRequestParams,
  Page<Site>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = (requestParams) => async () => {
  const sitesPageResponse: Page<SiteResponse> = {
    entries: [
      {
        id: '1',
        organization_id: '1',
        name: 'Store 1455',
        city: 'Plainstown',
        state: 'PN',
        location_count: 10,
        status: 'status',
      },
      {
        id: '1',
        organization_id: '1',
        name: 'Store 1556',
        city: 'Plainstown',
        state: 'PN',
        location_count: 12,
        status: 'status',
      },
    ],
    page: 1,
    page_size: 2,
    total_entries: 2,
    total_pages: 1,
  }
  return await Promise.resolve<Page<Site>>({
    ...sitesPageResponse,
    entries: sitesPageResponse.entries.map(mapSiteResposeToSite),
  })
}
