import React, { useState } from 'react'
import { Stack } from '@mui/material'
import { Button, TextField, Typography } from 'flanders-common-ui'
import { useNavigate } from 'react-router-dom'
import { login } from 'routes/routes'

export type ForgotPasswordScreenProps = {
  handleClickSubmit: (email: string) => void
}
const ForgotPasswordPage: React.FC<ForgotPasswordScreenProps> = ({
  handleClickSubmit,
}) => {
  const [emailDraft, setEmailDraft] = useState('')
  const navigate = useNavigate()

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailDraft(event.target.value)
  }

  return (
    <Stack spacing={3}>
      <Stack alignItems="center">
        <Typography variant="h5" fontWeight="bold">
          Forgot Password
        </Typography>
        <Typography variant="subtitle1" color="gray">
          Enter your email address and we&apos;ll send you a link to reset your
          password.
        </Typography>
      </Stack>

      <Stack width="100%">
        <TextField
          fullWidth
          id="email"
          label="Email"
          margin="dense"
          value={emailDraft}
          onChange={handleChangeEmail}
          variant="standard"
        />

        <Stack>
          <Button
            disabled={!emailDraft.length}
            onClick={() => handleClickSubmit(emailDraft)}
          >
            Submit
          </Button>
          <Button variant="text" onClick={() => navigate(login)}>
            Return to Login
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ForgotPasswordPage
