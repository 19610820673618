import { apiClient } from 'api/http-common'
import type { APIFunction, GenericErrorResponse } from 'api/http-common'
import type { UserTokens, UserTokensResponse } from './users-types'
import { mapUserTokensResponseToUserTokens } from './mapUserTokensResponseToUserTokens'

export const refreshAccessToken: APIFunction<string, UserTokens> = async (
  refreshToken
) => {
  try {
    const response = await apiClient.post<UserTokensResponse>(
      '/users/refresh',
      {
        refresh_token: refreshToken,
      }
    )

    return mapUserTokensResponseToUserTokens(response.data)
  } catch (err) {
    const axiosError = err as GenericErrorResponse

    if (axiosError.response?.status === 401) {
      throw axiosError
    }

    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
