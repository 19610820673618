import React from 'react'
import { Divider, Grid, Paper, Stack } from '@mui/material'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import {
  AboveFoldLayout,
  Button,
  Fixture,
  LocationStatus,
  StatusChart,
  TableBanner,
  Typography,
} from 'flanders-common-ui'
import { FindInPage } from '@mui/icons-material'
import { GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid'
import { Location, useFetchLocationsListPaginated } from 'api/locations'
import { RouteLabels } from 'routes/route-labels'
import DataGrid from 'components/molecules/DataGrid'
import { sites } from 'routes/routes'
import { locationFixtureTypeComparator } from 'utils/location-comparators'
import { formatFixtureType } from 'utils/formatFixtureType'
import TestFailure from 'components/atoms/TestFailure'
import { useFetchSiteById } from 'api/sites'

const getStatus = (location: Location) => {
  const nodeStatus = location?.node?.priority_status
  const verifiedAt = location.visuallyVerifiedAt
  const lastTest = location?.lastTest?.status

  return verifiedAt === null
    ? 'unverified'
    : nodeStatus === 'offline'
    ? 'offline'
    : lastTest === 'failed'
    ? 'failed'
    : null
}

function useColumns(): GridColDef<Location>[] {
  const columns: GridColDef<Location>[] = [
    {
      field: 'name',
      headerName: 'Location Name',
      flex: 3,
      renderCell: ({ row: location }) => {
        return (
          <NavLink
            key={location.id}
            to={`/locations/${location.id}`}
            state={location}
          >
            {location.name}
          </NavLink>
        )
      },
    },
    {
      field: 'fixture.fixtureType',
      headerName: 'Fixture Type',
      flex: 1,
      sortComparator: locationFixtureTypeComparator,
      renderCell: ({ row: location }) => {
        const fixtureType = location.fixture?.fixtureType

        if (fixtureType === null || fixtureType === undefined) {
          return <span>--</span>
        }

        const formattedFixtureType = formatFixtureType(fixtureType)

        return (
          <Fixture
            fixtureType={formattedFixtureType.fixtureType}
            lampCount={formattedFixtureType.lampCount}
          />
        )
      },
    },
    {
      field: 'fixture.battery_model_number',
      headerName: 'Battery Part #',
      flex: 1,
      valueGetter: ({ row: location }) => {
        const batteryModelNumber = location.fixture?.batteryModelNumber

        return typeof batteryModelNumber === 'string'
          ? batteryModelNumber
          : '--'
      },
    },
    {
      field: 'issue_type',
      headerName: 'Issue',
      flex: 2,
      valueGetter: (params) => params.row,
      sortComparator: (
        location1: Location,
        location2: Location,
        param1,
        param2
      ) => {
        const status1 = getStatus(location1)
        const status2 = getStatus(location2)

        return gridStringOrNumberComparator(status1, status2, param1, param2)
      },
      renderCell: ({ row: location }) => {
        const status = getStatus(location)
        const locationId: string = location.id

        switch (status) {
          case 'unverified': {
            return (
              <LocationStatus
                data-test-id={`location-requiring-attention-status-chip-${locationId}`}
                status="Unverified"
                description="Verification Required"
              />
            )
          }

          case 'offline': {
            return (
              <LocationStatus
                data-test-id={`location-requiring-attention-status-chip-${locationId}`}
                status="Offline"
                description="No Connection"
              />
            )
          }

          case 'failed': {
            return (
              <TestFailure sensorResults={location?.lastTest?.sensorResults} />
            )
          }

          default: {
            return <span>--</span>
          }
        }
      },
    },
  ]

  return columns
}

const SiteDetailsPage: React.FC = () => {
  const navigate = useNavigate()
  const { siteId = '' } = useParams<{ siteId: string }>()
  const { isLoading, data: site } = useFetchSiteById(siteId)
  const columns = useColumns()
  // TODO: Pending site overview API development
  const healthyCount = 1
  const requireAttentionCount = 2
  const runningCount = 3
  const totalCount = 6

  return isLoading || !site ? (
    <>Loading</>
  ) : (
    <Grid container spacing={1}>
      <AboveFoldLayout
        breadcrumbRoutes={[RouteLabels[1]]}
        heading={site.name}
      />

      <Grid container item spacing={2}>
        <Grid item xs={12} lg={8}>
          <Stack component={Paper} spacing={2} px={2} py="20px" mb={3}>
            <Typography variant="h3" weight="bold">
              Site Overview
            </Typography>
            <Divider variant="fullWidth" />
            <Grid container rowGap={3}>
              <StatusChart
                healthy={healthyCount}
                requireAttention={requireAttentionCount}
                running={runningCount}
                targetName="SITES"
                totalCount={totalCount}
              />
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    endIcon={<FindInPage />}
                    onClick={() => {
                      navigate(`${sites}/${siteId}/locations`)
                    }}
                  >
                    View All Locations
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Stack>

          <Stack width="100%">
            <TableBanner
              requireAttentionCount={requireAttentionCount}
              targetNamePlural="Locations"
              targetNameSingular="Location"
            />
            <DataGrid<Location>
              columns={columns}
              dataId="locations-requiring-attention-table"
              requestExtraParams={{}}
              useFetchPaginatedData={useFetchLocationsListPaginated}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Stack spacing={2} maxWidth={375}></Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SiteDetailsPage
