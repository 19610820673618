import React from 'react'
import { dashboard, sites, users } from './routes'
import {
  BusinessOutlined,
  DashboardOutlined,
  PersonOutlineOutlined,
  SvgIconComponent,
} from '@mui/icons-material'

export interface RouteLabel {
  path: string
  label: React.ReactNode | string
  Icon?: SvgIconComponent | (() => JSX.Element)
}

export type NavRoute<T> = {
  key: T
  path: string
  label: string
  Icon?: SvgIconComponent | (() => JSX.Element)
}

export type NavProps<T> = {
  navRoutes: NavRoute<T>[]
}

export type Label = 'Dashboard' | 'Sites' | 'Users' | 'UsersInvite'

export const RouteLabels: NavRoute<Label>[] = [
  {
    key: 'Dashboard',
    Icon: DashboardOutlined,
    label: 'Dashboard',
    path: dashboard,
  },
  {
    key: 'Sites',
    Icon: BusinessOutlined,
    label: 'Sites',
    path: sites,
  },
  {
    key: 'Users',
    Icon: PersonOutlineOutlined,
    label: 'Users',
    path: users,
  },
  // {
  //   key: 'UsersInvite',
  //   label: 'New User',
  //   path: usersInvite,
  // },
]
