import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  dashboard,
  forgotPassword,
  login,
  siteDetails,
  siteLocationDetails,
  siteLocations,
  sites,
  userDetails,
  userInviteAccept,
  userInviteSend,
  users,
} from 'routes/routes'
// import AuthenticatedRoute from 'routes/AuthenticatedRoute'
import DashboardPage from 'pages/dashboard/DashboardPage'
import ForgotPasswordPage from 'pages/login/ForgotPasswordPage'
import LoginPage from 'pages/login/LoginPage'
import SidebarNavigationLayout from 'components/templates/navigation/SidebarNavigationLayout'
import SitesPage from 'pages/sites/SitesPage'
import UsersPage from 'pages/users/UsersPage'
import UserDetailsPage from 'pages/users/UserDetailsPage'
import UserInviteSend from 'pages/users/UserInviteSend'
import UserInviteAccept from 'pages/users/UserInviteAccept'
import VisitorLayout from 'components/templates/visitor/VisitorLayout'
import LocationsPage from 'pages/locations/LocationsPage'
import SiteDetailsPage from 'pages/sites/SiteDetailsPage'
import LocationDetailsPage from 'pages/locations/LocationDetailsPage'

const App: React.FC = () => {
  return (
    <Routes>
      {/* Unauthenticated Routes */}
      <Route path="/" element={<VisitorLayout />}>
        <Route index element={<LoginPage />} />
        <Route path={login} element={<LoginPage />} />
        <Route path={forgotPassword} element={<ForgotPasswordPage />} />
        <Route path="*" element={<div>Not Found</div>} />
        <Route path={userInviteAccept} element={<UserInviteAccept />} />
      </Route>

      {/* Authenticated Routes */}
      <Route
        path={dashboard}
        element={
          // <AuthenticatedRoute>
            <SidebarNavigationLayout />
          // </AuthenticatedRoute>
        }
      >
        <Route index element={<DashboardPage />} />
      </Route>

      <Route
        path={sites}
        element={
          // <AuthenticatedRoute>
            <SidebarNavigationLayout />
          // </AuthenticatedRoute>
        }
      >
        <Route index element={<SitesPage />} />
        <Route path={siteDetails} element={<SiteDetailsPage />} />
        <Route path={siteLocations} element={<LocationsPage />} />
        <Route path={siteLocationDetails} element={<LocationDetailsPage />} />
      </Route>

      <Route
        path={users}
        element={
          // <AuthenticatedRoute>
            <SidebarNavigationLayout />
          // </AuthenticatedRoute>
        }
      >
        <Route index element={<UsersPage />} />
        <Route path={userDetails} element={<UserDetailsPage />} />
        <Route path={userInviteSend} element={<UserInviteSend />} />
      </Route>
    </Routes>
  )
}

export default App
