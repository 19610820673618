import type { APICurriedFunction } from 'api/http-common'
import type { EMCAdmin, User } from './users-types'

export const fetchUserById: APICurriedFunction<string, User> = () => async () =>
  await Promise.resolve({
    id: '0',
    nameFirst: 'Mallory',
    nameLast: 'Archer',
    email: 'mallory@dangerzone.com',
    organizationId: 'the-figgis-agency',
    role: 'emc-admin' as EMCAdmin,
  })
