import React from 'react'
import { NavLink } from 'react-router-dom'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import theme from 'theme'
import { Typography } from 'flanders-common-ui'
import { Label, NavRoute } from 'routes/route-labels'

const linkStylesCommon = {
  textDecoration: 'none',
  display: 'block',
}

const linkActiveStyles = {
  ...linkStylesCommon,
  color: theme.palette.grey[900],
  backgroundColor: theme.palette.UI.light,
}
const linkInactiveStyles = {
  ...linkStylesCommon,
  color: theme.palette.grey[600],
}

export type NavProps = {
  navRouteData: NavRoute<Label>[]
}

const Nav: React.FC<NavProps> = ({ navRouteData }) => (
  <List component="nav" style={{ padding: 0 }}>
    {navRouteData.map((data) => (
      <NavLink
        key={data.label}
        to={data.path}
        style={({ isActive }) =>
          isActive ? linkActiveStyles : linkInactiveStyles
        }
      >
        <ListItemButton
          style={{
            borderBottom: `solid 1px ${theme.palette.UI.main}`,
            paddingTop: theme.units.large,
            paddingBottom: theme.units.large,
          }}
        >
          <ListItemIcon
            style={{
              color: 'inherit',
            }}
          >
            {data.Icon && <data.Icon />}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body" weight="semiBold">
                {data.label}
              </Typography>
            }
          />
        </ListItemButton>
      </NavLink>
    ))}
  </List>
)

export default Nav
