import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { NavLink } from 'react-router-dom'
import DataGrid from 'components/molecules/DataGrid'
import { Paper, Stack } from '@mui/material'
import { useFetchSitesListPaginated, Site } from 'api/sites'
import { AboveFoldLayout } from 'flanders-common-ui'

function useColumns(): GridColDef<Site>[] {
  const columns: GridColDef<Site>[] = [
    {
      field: 'name',
      headerName: 'Site Name',
      flex: 2,
      renderCell: ({ row: site }) => {
        return (
          <NavLink key={site.id} to={`/sites/${site.id}`}>
            {site.name}
          </NavLink>
        )
      },
    },
    {
      field: 'address',
      headerName: 'City/State',
      flex: 1,
      valueGetter: ({ row: site }) => `${site.city}, ${site.state}`,
    },
    {
      field: 'locationsCount',
      headerName: 'Locations',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
  ]

  return columns
}

const SitesPage: React.FC = () => {
  const columns = useColumns()

  return (
    <>
      <AboveFoldLayout breadcrumbRoutes={[]} heading="Sites" />

      <Stack component={Paper} spacing={1} px={2} pt="20px">
        <Stack width="100%">
          <DataGrid<Site>
            columns={columns}
            dataId="sites-table"
            requestExtraParams={{}}
            useFetchPaginatedData={useFetchSitesListPaginated}
          />
        </Stack>
      </Stack>
    </>
  )
}

export default SitesPage
