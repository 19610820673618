import { APICurriedFunction } from 'api/http-common'
import { Page, PageRequestParams } from 'api/page'
import { Admin, EMCAdmin, User, Viewer } from './users-types'

export type UsersListRequestParams = PageRequestParams

const emcAdmin: EMCAdmin = 'emc-admin'
const admin: Admin = 'admin'
const viewer: Viewer = 'viewer'

export const fetchUsersListPaginated: APICurriedFunction<
  UsersListRequestParams,
  Page<User>
> = () => async () =>
  await Promise.resolve({
    entries: [
      {
        id: '0',
        nameFirst: 'Mallory',
        nameLast: 'Archer',
        email: 'mallory@dangerzone.com',
        organizationId: 'the-figgis-agency',
        role: emcAdmin,
      },
      {
        id: '1',
        nameFirst: 'Sterling',
        nameLast: 'Archer',
        email: 'archer@dangerzone.com',
        organizationId: 'the-figgis-agency',
        role: admin,
      },
      {
        id: '2',
        nameFirst: 'Lana',
        nameLast: 'Kane',
        email: 'lana@dangerzone.com',
        organizationId: 'the-figgis-agency',
        role: viewer,
      },
    ],
    page: 1,
    page_size: 15,
    total_entries: 3,
    total_pages: 1,
  })
