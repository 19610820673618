import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { Location, useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'
import { fetchJWTLogin, UserCredentials, UserTokens } from 'api/users'
import { dashboard } from 'routes/routes'

export type UserAuthContext = {
  user?: UserTokens
  login: (data: UserCredentials) => Promise<void>
  logout: () => void
}

const initialContext = {
  login: () => Promise.resolve(),
  logout: () => {},
}

const AuthContext = createContext<UserAuthContext>(initialContext)

type AuthProviderProps = {
  initialUser?: UserTokens
}

function isFromState(state: unknown): state is { from?: Location } {
  return state !== null && state !== undefined && Object.hasOwn(state, 'from')
}

export const AuthProvider: React.FC<PropsWithChildren<AuthProviderProps>> = ({
  children,
  initialUser,
}) => {
  const [user, setUser] = useLocalStorage<UserTokens>('user', initialUser)
  const navigate = useNavigate()
  const location = useLocation()
  const from = isFromState(location.state) ? location.state.from : undefined

  // call this function when you want to authenticate the user
  const login = useCallback(
    async (credentials: UserCredentials) => {
      const tokens = await fetchJWTLogin(credentials)
      setUser(tokens)
      navigate(from?.pathname ?? dashboard, { replace: true })
    },
    [navigate, setUser, from?.pathname]
  )

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    setUser(undefined)
    navigate('/', { replace: true })
  }, [navigate, setUser])

  useEffect(() => {
    /**
     * Validate local storage which can change without triggering the above handleCacheClear hook
     * Cache will be forced clear when server responds with a 401 unauthenticated user response via http-common
     *
     * This is intentionally run on every page load and network request,
     * does not re-run when from client-side-ONLY interaction
     *
     */
    if (
      typeof user !== 'undefined' &&
      window.localStorage.user === 'undefined'
    ) {
      logout()
    }
  })

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user, login, logout]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
