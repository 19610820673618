import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserEdit, { UserEditProps } from 'components/organisms/forms/UserEdit'
import { Paper, Stack } from '@mui/material'
import UserInviteSent from './UserInviteSent'
import { users } from 'routes/routes'

const UserInviteSend: React.FC = () => {
  const [inviteSent, setInviteSent] = useState(false)

  const navigate = useNavigate();

  const userEditData: UserEditProps = {
    email: '',
    handleClickSave: () => { setInviteSent(true)},
    headingText: 'Add New User',
    isActiveUser: false,
    nameFirst: '',
    nameLast: '',
    role: 'viewer',
    subtitleText: 'Add a new user to your organization',
  }

  return (
    <Stack
      data-test-id="location-add-page-container"
      spacing={1}
      p={1}
      flexGrow={1}
    >
      <Paper
        sx={{
          py: 8,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          alignItems: 'center',
          width: '100%',
          flexGrow: 1,
          justifyContent: 'center',
        }}
      >
        { inviteSent ? (<UserInviteSent handleClickFinish={() => {navigate(users)}} handleClickSecondary={() => setInviteSent(false)}/>) : (<UserEdit {...userEditData}/>)}
      </Paper>
    </Stack>
  )
}

export default UserInviteSend
