import { apiClient } from 'api/http-common'
import type { APICurriedFunction } from 'api/http-common'
import type { FixtureType, Location, LocationResponseFixture } from './location-types'
import { LocationResponse, mapLocationResponseToLocation } from 'api/locations'

export const fetchLocationById: APICurriedFunction<string, Location> =
  (locationId) => async () => {
    const response = await apiClient.get<LocationResponse>(
      `locations/${locationId}`
    )

    return mapLocationResponseToLocation(response.data)
  }

  ///////////////
// TODO: Delete when API is implemented and/or UI is complete
// TODO: Be sure to update parent use function too!
export const mockFetchLocationById: APICurriedFunction<
string,
Location
> = () => {

return async () => {
  const fixtureType: FixtureType = 'combination_1'
  const fixture: LocationResponseFixture = {
    battery_model_number: 'batteryModelNumber',
    fixture_model_number: 'fixtureModelNumber',
    fixture_type: fixtureType,
    voltage: 123,
  }
  const response = await Promise.resolve({
    data: {
      id: 'id',
      fixture: fixture,
      last_test: null,
      name: 'LocationName',
      node: null,
      notes: 'notes',
      organization_id: '1',
      test_results: [],
      visually_verified_at: new Date("2022-09-18T12:00:30"),
    }
  })

  
  return mapLocationResponseToLocation(response.data)
  
}
}


